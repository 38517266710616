import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Scan from "./Scane/Scan";
import { useNavigate } from "react-router-dom";

const NFCScane = () => {
  const [actions, setActions] = useState(null);
  const [tableName, setTableName] = useState("");
  const { scan } = actions || {};
  const onHandleAction = (actions) => {
    setActions({ ...actions });
  };
  const navigate = useNavigate();
  const actionsValue = { actions, setActions };
  useEffect(() => {
    if (tableName) {
      navigate(`/?buid=771247277342722&table_no=1&idc=${tableName}`);
    }
  }, [tableName]);
  return (
    <>
      <Row className="wrapper mt-5">
        <Col md="12" lg="12" sm="12" xs="12" className="">
          <div
            style={{ flexDirection: "column" }}
            className="show_card_main_div"
          >
            <div className="btn-show-card">
              <div
                onClick={() =>
                  onHandleAction({ scan: "scanning", write: null })
                }
                className="card-btn text-center "
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img src="/img/play-white.png" className="mt-2" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <span className="starten-text text-[#fff;] ">Starten</span>
                </div>
              </div>

              {scan && (
                <Scan
                  tableName={tableName}
                  setTableName={setTableName}
                  actionsValue={actionsValue}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NFCScane;

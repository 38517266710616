import { useContext } from "react";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { FoodItemContext } from "../../../../contexts/FoodItemContext";
import "./headerStyle.css"
const Header = () => {
  const { theme } = useContext(ThemeContext);
  const { clearState } =
  useContext(FoodItemContext);
  const handleRefresh = () => {
    window.location.replace('/')
    clearState()

  };
  return (
    <>
      <div
        className="flex  py-2  w-full"
        style={{
          backgroundColor: theme.colors.primaryColor,
          height: "8%",
        }}
      >
        <div className="flex justify-end" style={{width:"55%"}}>
          <img
            src={theme.images.companyLogo}
            alt=""
            className="h-full items-center hover:opacity-80 cursor-pointer"
          />
        </div>
        <div className="flex justify-end     align-items-end refresh-button-main-div" >
          <div onClick={handleRefresh} className="refresh-button">abbrechen</div>
        </div>
      </div>
    </>
  );
};

export default Header;

import { useEffect } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainComponent from "./components/OrderDetail";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const tableInfo = useSelector((state: any) => state.table);

  const checkTableInfo = () => {
    if (
      !tableInfo?.orderInfo?.businessLocationId ||
      !tableInfo?.business_Info?.business_name ||
      !tableInfo.table
    ) {
      return window.location.replace("/");
    }
  };

  useEffect(() => {
    checkTableInfo();
  }, []);
  return (
    <div className="h-screen">
      <Header />
      <MainComponent />
      <Footer />
    </div>
  );
};

export default Dashboard;

import { useContext, useEffect } from "react";
import { FoodItemContext } from "../../../../../../../contexts/FoodItemContext";
import { ThemeContext } from "../../../../../../../contexts/ThemeContext";
import CardModal from "./CardDetailModal";
import Tooltip from "@mui/material/Tooltip";
import {
  Sync_Carts,
  SetCartData as SetCartDataAction,
} from "../../../../../../../store/actions/orderAction";
import { useDispatch } from "react-redux";

const MenuCard = () => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const {
    setSelectedFoodItem,
    selectedGroup,
    setShowAddFoodItemModal,
    showAddFoodItemModal,
  } = useContext(FoodItemContext);
  const group: any = selectedGroup;
  const handleCardDetailModal = (foodItem: any) => {
    dispatch(SetCartDataAction(foodItem));
    dispatch(Sync_Carts(foodItem));
    setShowAddFoodItemModal(true);
    setSelectedFoodItem(foodItem);
  };
  // clear out selected menu checkboxex on modal open and close
  useEffect(() => {
    const selectedCheckboxes = document.querySelectorAll(
      "input[class=selectedIngredients]"
    );
    selectedCheckboxes.forEach((checkbox: any) => {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    });
  }, [showAddFoodItemModal]);
  return (
    <>
      <div
        className="w-full  h-full relative"
        style={{ backgroundColor: theme.colors.secondaryColor }}
      >
        <div
          className={
            showAddFoodItemModal
              ? "opacity-50 w-full h-full p-6"
              : "w-full p-6 h-full"
          }
        >
          <div
            className="flex font-black text-3xl md:text-5xl  2xl:text-7xl  py-4 items-center mb-4"
            style={{
              height: "10%",
            }}
          >
            <p>{group.groupName}</p>
            <p className="text-white">*</p>
          </div>
          <div
            className="overflow-y-auto"
            style={{
              height: "90%",
            }}
          >
            <div className="flex  overflow-y-auto  ">
              <div className="flex flex-1  ">
                <div className="grid  grid-cols-1 sm:grid-cols-2 gap-3 lg:gap-y-7 ">
                  {group?.products?.map((fooditem: any) => {
                    return (
                      <div
                        key={fooditem._id}
                        className="bg-white px-4 py-4 rounded-md mt-1 flex flex-col h-fit sm:h-80  md:h-96 lg:h-900 2xl:h-600 "
                      >
                        <div className=" flex flex-col lg:gap-y-2 h-full">
                          <div className="mb-1 text-white flex">
                            <Tooltip
                              title={
                                fooditem?.allergens.length > 0
                                  ? fooditem?.allergens.map(
                                      (allergen: any) => allergen.title + " "
                                    )
                                  : "N/A"
                              }
                              arrow
                            >
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    fill: theme.colors.primaryColor,
                                  }}
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          </div>
                          <div
                            className=""
                            onClick={() => handleCardDetailModal(fooditem)}
                          >
                            <img
                              className="object-cover w-full h-32 md:h-44  lg:h-56 2xl:h-72"
                              src={fooditem.image}
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col">
                            <h1 className=" text-sm sm:text-base md:text-base xl:text-xl  font-extrabold text-center w-full  sm:text-start sm:truncate sm:w-full  ">
                              {fooditem.name}
                            </h1>
                            {/* removing html tags from food item description */}
                            <p className=" w-full line-clamp-1 sm:line-clamp-3 md:line-clamp-3 lg:line-clamp-5 xl:line-clamp-4 text-xs sm:text-sm md:text-base xl:text-lg ">
                              {}
                            </p>
                          </div>
                          <div className="flex flex-row text-base md:text-lg font-extrabold justify-between mt-2  md:mt-auto ">
                            <p>€ {fooditem.price}</p>
                            <button
                              className="rounded-md text-white text-base font-extrabold px-6 md:px-8 md:py-1 hover:opacity-70"
                              style={{
                                backgroundColor: theme.colors.primaryColor,
                              }}
                              onClick={() => handleCardDetailModal(fooditem)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            showAddFoodItemModal
              ? "absolute top-0 h-full w-full md:flex md:justify-center md:w-full"
              : "hidden"
          }
        >
          <CardModal />
        </div>
      </div>
    </>
  );
};
export default MenuCard;

import axios from "axios";

const baseDomain = process.env.REACT_APP_API_URL;
const baseURL = `${baseDomain}`;

const axiosObj = axios.create({
  baseURL
});
export default {
  checkUser({ idc, table_no, buid }) {
    return axiosObj
      .post(`/table-sessions/join`, {
        idc: idc,
        businessLocationId: buid,
        table_id: table_no
      })
      .then((res) => {
        return res.data;
      });
  },
};

import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { ThemeContext } from "../../../../../contexts/ThemeContext";
const RightSideBar = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className="w-2/3 xsm:w-9/12 md:w-4/5 lg:w-5/6"
      style={{
        backgroundColor: theme.colors.secondaryColor,
      }}
    >
      <Outlet />
    </div>
  );
};

export default RightSideBar;

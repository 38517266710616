import React from "react";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { render } from "react-dom";

render(
  <div>
    <Provider store={store}>
        <App />
    </Provider>
    </div>,
     document.getElementById("root")
);

import React from "react";
import { useNavigate } from "react-router-dom";

const OrderCompletion = () => {
  const navigate = useNavigate();
  const handleWelcomeClick = () => {
    navigate("/");
  };
  return (
    <div className="relative flex h-screen justify-center overflow-hidden bg-[#D6BE9A] font-semibold">
      <div className="flex flex-col items-center gap-8 justify-center">
        <h1
          className="flex text-white text-5xl font-bold"
          style={{ fontFamily: "" }}
        >
          Thank You
        </h1>
        <div className="flex flex-col items-center">
          <div className="flex text-center mb-5 text-4xl font-bold uppercase">
            For Your order
          </div>
          <div className="bg-[#897A53] w-full sm:w-96 h-0.5"></div>
        </div>
        <div className="flex gap-2">
          <h2 className="flex  justify-content:center sm:text-3xl uppercase">
            Order{" "}
          </h2>
          <p className="font-extrabold sm:text-3xl uppercase"> 7321B</p>
          <h2 className="sm:text-2xl uppercase"> has been placed</h2>
        </div>
        <div
          className="hover:opacity-70 cursor-pointer"
          onClick={handleWelcomeClick}
        >
          <img
            src="https://i.ibb.co/JqnD1L5/Asst-Screen-UI.png"
            alt="Asst-Screen-UI"
            className=" w-24 h-24"
          />
        </div>
        <p className="flex justify-content:center sm:text-3xl uppercase">
          Wait for pager to ring when order is ready
        </p>
      </div>
    </div>
  );
};

export default OrderCompletion;

// Root Reducer
import { combineReducers } from "redux";
import orderReducer from "./orderReducer";
import tableReducer from "./tableReducer";

export let rootReducer = combineReducers({
  table: tableReducer,
  order: orderReducer,
});

export default rootReducer;

import axios from "axios";
const baseDomian = `${process.env.REACT_APP_API_URL}`;
const baseURL = `${baseDomian}`;
let axiosObj;
axiosObj = axios.create({
  baseURL
});

axiosObj.interceptors.request.use(
  function (config) {
    const blId = sessionStorage.getItem("blId");
    if (blId) {
      config.headers["blId"] = blId;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosObj;

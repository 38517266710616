import axios from "axios";
import { getErrorMessage } from "../utils";
const apiUrl = process.env.REACT_APP_API_URL;

export const getMenu = () => {
  let groups: any = [];
  let uniqueGroups: any = [];
  let products: any = [];
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${apiUrl}/menus/menus/771247277342722`)
        .then((response) => {
          //get all groups and their ids
          for (let product of response.data.data) {
            let demo = {
              groupName: "",
              groupId: "",
              groupImage: "",
              products: [],
            };
            demo.groupName = product.group;
            demo.groupId = product.id;
            demo.groupImage = product.image || product?.groupImage;
            groups.push(demo);
            products.push(product);
          }

          //filter out duplicate groups and their ids
          uniqueGroups = groups.filter(
            (value: any, index: number, self: any) =>
              index === self.findIndex((t: any) => t.groupId === value.groupId)
          );
          // populating products array of uniqueGroups
          for (let i = 0; i < uniqueGroups.length; i++) {
            for (let j = 0; j < products.length; j++) {
              if (uniqueGroups[i].groupId === products[j].id) {
                uniqueGroups[i].products.push(products[j]);
              }
            }
          }
          resolve(uniqueGroups);
        })
        .catch((err) => {
          console.log("there is something error" + err);
          reject("there is something error " + err);
        });
    } catch (error) {
      reject(getErrorMessage(error));
    }
  });
};

import React, { useEffect } from "react";
import { defaultTheme } from "./ContextDefaults";
import { ThemeContextType, ThemeType } from "./ContextTypes";
export const ThemeContext = React.createContext<ThemeContextType>({
  theme: defaultTheme,
});

const ThemeProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [theme] = React.useState<ThemeType>(defaultTheme);

  useEffect(() => {
    // THEME comes from backend - set here
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

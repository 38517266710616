import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FoodItemContext } from "../../../../contexts/FoodItemContext";
import { ThemeContext } from "../../../../contexts/ThemeContext";
const Footer = () => {
  const { theme } = useContext(ThemeContext);
  const { setSelectedOutlet, selectedOutlet, ordersBlip, setOrdersBlip } =
    useContext(FoodItemContext);
  const navigate = useNavigate();
  const handleDashbClick = () => {
    navigate("/dashboard");
    setSelectedOutlet("Dashboard");
  };
  const handleOderClick = () => {
    navigate("/dashboard/order");
    setSelectedOutlet("Order");
    setOrdersBlip(false);
  };
  const handleHelpClick = () => {
    navigate("/help");
    setSelectedOutlet("help");
  };
  return (
    <div
      className="flex justify-center"
      style={{ backgroundColor: theme.colors.primaryColor, height: "12%" }}
    >
      <div className=" flex justify-between  sm:justify-evenly py-4 px-2 w-80 sm:w-full ">
        <div className="flex flex-col justify-center items-center ">
          <button
            className="rounded-full p-1 hover:opacity-70 "
            style={{
              backgroundColor:
                selectedOutlet === "Dashboard"
                  ? theme.colors.tertiary2
                  : theme.colors.tertiary,
            }}
            onClick={handleDashbClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="white"
              className="w-6 h-6 md:w-8 md:h-8 lg:h-9 lg:w-9 2xl:h-10 2xl:w-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </button>
          <p
            className={
              selectedOutlet === "Dashboard"
                ? " font-semibold  tracking-tighter text-black sm:font-bold text-base md:text-lg 2xl:text-xl sm:tracking-tight  lg:tracking-tight  "
                : " font-semibold  tracking-tighter text-white sm:font-bold text-base md:text-lg 2xl:text-xl sm:tracking-tight  lg:tracking-tight   "
            }
          >
            MAIN MENU
          </p>
        </div>
        <div
          className="flex flex-col justify-center items-center relative"
          onClick={handleOderClick}
        >
          <button
            className="rounded-full p-1 hover:opacity-70 "
            style={{
              backgroundColor:
                selectedOutlet === "Order"
                  ? theme.colors.tertiary2
                  : theme.colors.tertiary,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="white"
              className="w-6 h-6 md:w-8 md:h-8 lg:h-9 lg:w-9 2xl:h-10 2xl:w-10 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </button>
          <p
            className={
              selectedOutlet === "Order"
                ? " font-semibold  tracking-tighter text-black sm:font-bold text-base md:text-lg 2xl:text-xl sm:tracking-tight "
                : " font-semibold  tracking-tighter text-white sm:font-bold text-base md:text-lg 2xl:text-xl sm:tracking-tight "
            }
          >
            ORDER
          </p>
          <span
            className={
              ordersBlip
                ? "rounded-full w-2 h-2  md:w-3 md:h-3 lg:h-3 lg:w-3 xl:h-3 xl:w-3 absolute bg-red-600 -top-0 right-2 sm:-top-1 sm:right-4  md:-top-2 md:right-2 lg:-top-2  lg:right-1   "
                : "hidden"
            }
          ></span>
        </div>
        <div
          className="flex flex-col justify-center items-center"
          onClick={handleHelpClick}
        >
          <button
            className="rounded-full p-1 hover:opacity-70 "
            style={{
              backgroundColor:
                selectedOutlet === "help"
                  ? theme.colors.tertiary2
                  : theme.colors.tertiary,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="white"
              className="w-6 h-6 md:w-8 md:h-8 lg:h-9 lg:w-9 2xl:h-10 2xl:w-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
              />
            </svg>
          </button>
          <p
            className={
              selectedOutlet === "help"
                ? " font-semibold  tracking-tighter text-base md:text-lg 2xl:text-xl text-black sm:font-semibold  sm:tracking-tight "
                : " font-semibold  tracking-tighter text-base md:text-lg 2xl:text-xl text-white sm:font-bold  sm:tracking-tight "
            }
          >
            HELP
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;

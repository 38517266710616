import {  useSelector } from "react-redux";
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import Footer from "../Dashboard/components/Footer";
import Header from "../Dashboard/components/Header";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../services/sockets/socket";


const Help = () => {
  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(true);
  const [serviceMsg, setServeiceMsg] = useState("");
  const tableInfo = useSelector((state:any)=>state?.table);
  
  const handleSubmit = () => {
    setOpen(false);
     const msg = {
      blId: tableInfo?.orderInfo?.businessLocationId,
      problemID: uuidv4(),
      tableNo: tableInfo?.orderInfo?.tableNumber,
      sessionId: tableInfo?.sessionId,
      message: serviceMsg,
      service: true
     };
    socket.emit("service_notification", msg);
    setServeiceMsg("");
  };

  return (
    <div className="h-screen">
      <Header />
      <div
        className="h-4/5 flex justify-center flex-col items-center "
        style={{ backgroundColor: theme.colors.secondaryColor }}
      >
        <div className="flex flex-col justify-center items-center gap-y-1 md:gap-y-5 lg:gap-y-4">
          <div className="h-16 md:h-28 lg:h-20 ">
            <img className="h-full" src={theme.images.companyMainLogo} alt="" />
          </div>
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-col justify-center items-center md:gap-y-1 lg:gap-y-0">
              <div
                className=" text-xl font-extrabold md:text-2xl"
                style={{ color: theme.colors.primaryColor }}
              >
                <p>SERVICE</p>
              </div>
              <div className=" font-bold text-center md:text-lg">
                <p>BRAUCHST DU HILFE?</p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div>
                  <svg
                    className="svg-icon h-20  md:h-24 lg:h-24"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z" />
                  </svg>
                </div>
                <div>
                  <svg
                    version="1.1"
                    id="Layer_1"
                    className="h-14 -ml-5 md:h-14 lg:h-14"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path d="M256,490.7V512c23.6,0,42.7-19.1,42.7-42.7c0-23.6-19.1-42.7-42.7-42.7c-23.6,0-42.7,19.1-42.7,42.7 c0,23.6,19.1,42.7,42.7,42.7V490.7v-21.3l0,0v0v0V490.7z M277.3,384v-64c0-8.9,1.2-14,2.3-16.7c0.9-2,1.7-3.1,3.2-4.6 c2.3-2.2,7.2-5.3,15.3-9c8-3.8,18.7-8.2,31.4-14.6c24.8-12.3,44.1-30.5,56.9-52.4c12.8-21.8,18.9-47.1,18.9-73.4 C405.3,66.9,338.5,0,256,0c-82.5,0-149.3,66.9-149.3,149.3h42.7c0-29.5,11.9-56.1,31.2-75.4c19.4-19.3,45.9-31.2,75.4-31.2 c29.5,0,56.1,11.9,75.4,31.2c19.3,19.4,31.2,45.9,31.2,75.4c0,19.7-4.5,37.2-13.1,51.8c-8.6,14.6-21.2,26.8-39.1,35.8 c-10,5-19,8.9-27.3,12.7c-6.3,2.9-12.3,5.8-18.1,9.4c-8.7,5.3-17.5,13.2-23,24.1c-5.5,10.9-7.4,23-7.4,36.9l0,64H277.3z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className={open ? " flex flex-col md:gap-y-1 lg:gap-y-0" : "hidden"}
          >
            <div>
              <textarea
                name=""
                id=""
                // cols={50}
                rows={3}
                className="p-3 border-2 border-gray-600 rounded-md resize w-80 sm:w-450 md:w-900 md:h-40 lg:h-36"
                placeholder="type here!"
                onChange={(e)=>setServeiceMsg(e.target.value)}
              ></textarea>
            </div>
            <div className="w-full flex justify-end">
              <button
                className="text-white py-2 px-4 rounded-md "
                style={{ backgroundColor: theme.colors.primaryColor }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <div
            className={
              open ? "hidden" : " flex flex-col items-center gap-y-4 mt-2"
            }
          >
            <div
              className="text-xl font-extrabold text-center md:text-2xl"
              style={{ color: theme.colors.primaryColor }}
            >
              <p>SUBMISSION SUCCESSFUL!</p>
            </div>
            <div className="font-semibold text-center md:text-lg ">
              <p>
                VIELEN DANK WIR HABEN DEINE NACHRICHT ERHALTEN UND KÜMMERN UNS
                UM DEINE ANLIEGEN
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Help;

import React, { useEffect } from "react";
import { getMenu } from "../services/menu";
import {
  defaultFoodCategory,
  defaultFoodItems,
  defaultFoodOrders,
} from "./ContextDefaults";
import {
  FoodCategoryType,
  FoodItems,
  FoodItemsType,
  FoodOrdersType,
  FoodItemContextType,
  GroupType,
} from "./ContextTypes";

export const FoodItemContext = React.createContext<FoodItemContextType>({
  foodCategories: defaultFoodCategory,
  isLoading: false,
  setIsLoading: () => null,
  setFoodItem: () => null,
  setSelectedOutlet: () => null,
  setupdatedCount: () => null,
  setSelectedCategory: () => null,
  setSelectedFoodItem: () => null,
  setFoodOrder: () => null,
  selectedFoodItem: {
    _id: "",
    name: "",
    image: "",
    description: "",
    ingredients: [],
    mandatory: [],
    single_only: [],
    price: 0,
    allergens: [],
    course:"",
    preperation_time: 0,
    sku:"",
    group:"",
    blId:"",

  },
  foodItem: defaultFoodItems,
  foodOrder: defaultFoodOrders,
  selectedOutlet: "",
  selectedCategory: "Insalata",
  updatedCount: 1,
  selectedGroup: [],
  setSelectedGroup: () => null,
  groups: [],
  setGroups: () => null,
  selectedIngredients: [],
  setSelectedIngredients: () => null,
  orderId: 0,
  setOrderId: () => null,
  ordersBlip: false,
  setOrdersBlip: () => false,
  showAddFoodItemModal: false,
  setShowAddFoodItemModal: () => false,
  clearState: () => null,
});

const FoodItemProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [selectedOutlet, setSelectedOutlet] = React.useState<string>("");
  const [updatedCount, setupdatedCount] = React.useState(1);
  const [selectedCategory, setSelectedCategory] =
    React.useState<string>("Insalata");
  const [selectedFoodItem, setSelectedFoodItem] = React.useState<FoodItems>({
    _id: "",
    name: "",
    image: "",
    description: "",
    ingredients: [],
    mandatory: [],
    single_only: [],
    price: 0,
    allergens: [],
    course:"",
    preperation_time: 0,
    sku:"",
    group:"",
    blId:"",
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [foodCategories] =
    React.useState<FoodCategoryType[]>(defaultFoodCategory);
  const [foodItem, setFoodItem] =
    React.useState<FoodItemsType[]>(defaultFoodItems);
  const [foodOrder, setFoodOrder] =
    React.useState<FoodOrdersType[]>(defaultFoodOrders);
  const [groups, setGroups] = React.useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<GroupType[]>([]);
  const [selectedIngredients, setSelectedIngredients] = React.useState<[]>([]);
  const [orderId, setOrderId] = React.useState<number>(0);
  const [ordersBlip, setOrdersBlip] = React.useState<boolean>(false);
  const [showAddFoodItemModal, setShowAddFoodItemModal] =
    React.useState<boolean>(false);

  useEffect(() => {
    // THEME comes from backend - set here
    setIsLoading(true);
    getMenu()
      .then((response: any) => {
        setSelectedGroup(response[0]);
        setGroups(response);
        setSelectedCategory(response[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setIsLoading(false);
      });
  }, []);
  const clearState = () => {
    setSelectedOutlet('');
    setupdatedCount(1);
    setSelectedCategory("Insalata");
    setSelectedFoodItem({
      _id: "",
      name: "",
      image: "",
      description: "",
      ingredients: [],
      mandatory: [],
      single_only: [],
      price: 0,
      allergens: [],
      course:"",
      preperation_time: 0,
      sku:"",
      group:"",
      blId:"",
    })
    setIsLoading(false);
    setFoodItem(defaultFoodItems);
    setFoodOrder(defaultFoodOrders);
    setGroups([]);
    setSelectedGroup([]);
    setSelectedIngredients([]);
    setOrderId(0);
    setOrdersBlip(false);
    setShowAddFoodItemModal(false)
  };
 

  return (
    <FoodItemContext.Provider
      value={{
        isLoading,
        setIsLoading,
        foodCategories,
        foodItem,
        setFoodItem,
        selectedOutlet,
        setSelectedOutlet,
        setSelectedCategory,
        selectedCategory,
        setSelectedFoodItem,
        selectedFoodItem,
        foodOrder,
        setFoodOrder,
        updatedCount,
        setupdatedCount,
        selectedGroup,
        setSelectedGroup,
        groups,
        setGroups,
        selectedIngredients,
        setSelectedIngredients,
        orderId,
        setOrderId,
        ordersBlip,
        setOrdersBlip,
        showAddFoodItemModal,
        setShowAddFoodItemModal,
        clearState,
      }}
    >
      {children}
    </FoodItemContext.Provider>
  );
};

export default FoodItemProvider;

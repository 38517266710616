import "./Scanner.css";

const Scanner = ({ cardsuccessScan }) => {
  // const { actions, setActions } = props.actionsValue;
  return (
    <div className="scanner-container mt-5">
      {cardsuccessScan && (
        <img src="/img/check.png" alt="check" className="checkimg mb-3" />
      )}
      <img src="/img/scan.png" alt="spinning log" className="scanner-image" />
      <p className="scanner-text text-center">
        BITTE SCANNE
        <br />
        DEINE KARTE
      </p>
    </div>
  );
};

export default Scanner;

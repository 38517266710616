import Dashboard from "./components/Dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeProvider from "./contexts/ThemeContext";
import Welcome from "./components/Welcome/Welcome.js";
import OrderCompletion from "./components/OrderCompletion/OrderCompletion";
import DragNDrop from "./components/DragNDrop";
import MenuCard from "./components/Dashboard/components/OrderDetail/RightSideBar/components/MenuDetail";
import ScanningCard from "./components/OrderPlaced";
import FoodItemProvider from "./contexts/FoodItemContext";
import Help from "./components/Help";
import CreatedSessionProvider from "./contexts/SessionContext";
import { firebase } from "./store/firebase";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersFromFirebae } from "./store/actions/orderAction";
import { updateSessionTableNumber } from "./store/actions/tableActions";

const App = () => {
  const tableInfo = useSelector((state: any) => state.table);
  const dispatch = useDispatch();
  useEffect(() => {
    if (tableInfo?.sessionId) firebaseOrderListner();
  }, [tableInfo?.sessionId]);
  const firebaseOrderListner = async () => {
    await firebase
      .firestore()
      .collection("Local_Order")
      .where("sessionId", "==", tableInfo?.sessionId)
      .onSnapshot((snapshot) => {
        let updatedData = snapshot.docs.map((doc) => doc.data());
        if (updatedData?.length) {
          dispatch(updateSessionTableNumber(updatedData[0]?.tableNumber));
          dispatch(getOrdersFromFirebae(updatedData[0]));
        }
      });
  };

  return (
    <div>
      <CreatedSessionProvider>
        <FoodItemProvider>
          <ThemeProvider>
            <BrowserRouter>
              <Routes>
                <Route path="ordercompletion" element={<OrderCompletion />} />
                <Route path="/" element={<Welcome />} />
                {/* <Route path="/Welcome" element={<Welcome />} /> */}
                <Route path="/drag" element={<DragNDrop />} />
                <Route path="/OrderPlaced" element={<ScanningCard />} />
                <Route path="/help" element={<Help />} />
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route path="" element={<MenuCard />} />
                  <Route path="order" element={<DragNDrop />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </FoodItemProvider>
      </CreatedSessionProvider>
    </div>
  );
};

export default App;

import UserRepository from "./UserRepository";
import Order from "./OrderRepository";

const repositories = {
  user: UserRepository,
  order: Order

 
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};

import React, { useEffect } from "react";
import {} from "./ContextDefaults";
import { CreatedSession, CreatedSessionType } from "./ContextTypes";

export const CreatedSessionContext = React.createContext<CreatedSessionType>({
  setCreatedSession: () => null,
  CreatedSession: {
    bump: false,
    businessLocationId: 0,
    createdAt: "",
    space_used: 0,
    station: "",
    status: "",
    table_id: "",
    table_name: "",
    __v: 0,
    _id: "",
  },
});

const CreatedSessionProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [CreatedSession, setCreatedSession] = React.useState<CreatedSession>({
    bump: false,
    businessLocationId: 0,
    createdAt: "",
    space_used: 0,
    station: "",
    status: "",
    table_id: "",
    table_name: "",
    __v: 0,
    _id: "",
  });

  useEffect(() => {
    // THEME comes from backend - set here
  }, []);

  return (
    <CreatedSessionContext.Provider
      value={{
        CreatedSession,
        setCreatedSession,
      }}
    >
      {children}
    </CreatedSessionContext.Provider>
  );
};

export default CreatedSessionProvider;

// import { applyMiddleware, compose, createStore } from "redux";
import appReducer from "./reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["table","order"] // which reducer want to store
};

const rootReducer = (state:any, action:any) => {
  if (action.type === "LOG_OUT") {
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const composeEnhancers = composeWithDevTools({});
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch

export { persistor, store };

import axios from "axios";
import {
  SET_TABLE,
  BUS_INFO,
  ORDER_INFO,
  SESSION_ID,
  SET_TABLE_LOADING,
  GET_FLOOR_PLANS_SUCCESSED,
  UPDATE_SESSION_TABLE_REQUEST,
  UPDATE_SESSION_TABLE_SUCCESSED,
  UPDATE_SESSION_TABLE_FAIL,
  GET_TEXT,
  SET_TEXT
} from "../types";

export const getBusinessInfo = (payload) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}/business`,
      payload
    );

    dispatch({
      type: BUS_INFO,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    dispatch(setTableLoading(false));
    alert("ERROR HEREE (getBusinessInfo)" + err);
  }
};
export const tableID = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SESSION_ID,
      payload,
    });
  } catch (error) {
    alert(
      "Something went wrong with your Session. n/ Please reload & try again:" +
        error
    );
  }
};
export const orderInfo = (payload) => {
  return {
    type: ORDER_INFO,
    payload,
  };
};

export const setTable = (payload) => {
  return {
    type: SET_TABLE,
    payload,
  };
};
export const setTableLoading = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLE_LOADING,
      payload: payload,
    });
  } catch (err) {
    console.log("error " + err);
  }
};
export const getBusinessFlorrPlans = (payload) => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/floor-plans/${payload}`);
    dispatch({
      type: GET_FLOOR_PLANS_SUCCESSED,
      payload: res?.data?.data?.sort((a, b) => {
        if (!b.number) {
          return -1;
        }
        return a.number - b.number;
      })
    });
  } catch (err) {
    dispatch(setTableLoading(false));
    alert("ERROR HEREE (getBusinessFlorrPlans)" + err);
  }
};
export const updateSessionTable = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SESSION_TABLE_REQUEST
    });
    let res = await axios.put(
      `${process.env.REACT_APP_API_URL}/table-sessions/update-session-table`,
      payload
    );
    dispatch({
      type: UPDATE_SESSION_TABLE_SUCCESSED,
      payload: res?.data?.data?.table_id
        });
    return res?.data;
  } catch (err) {
    dispatch({
      type: UPDATE_SESSION_TABLE_FAIL
    });
    console.log("ERROR HEREE (updateSessionTable)" + err);
    return err;
  }
};
export const updateSessionTableNumber = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SESSION_TABLE_SUCCESSED,
      payload: payload
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SESSION_TABLE_FAIL
    });
    console.log("ERROR HEREE (updateSessionTable)" + err);
    return err;
  }
};
export const getTextAndHeading = (payload) => async (dispatch) => {
  dispatch({ type: GET_TEXT });

  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/business/getLayoutText/${payload}`
    );
    if (response.data.success) {
      dispatch({ type: SET_TEXT, payload: response.data.data });
    }
  } catch (error) {
    console.log("Error updating App Colors", error);
    // alert("Text Didnt Send");
  }
};
import React, { useContext, useEffect } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import Footer from "../Dashboard/components/Footer";
import Header from "../Dashboard/components/Header";
import { useSelector, useDispatch } from "react-redux";
import { updatePlaceOrderStatus } from "../../store/actions/orderAction";
import { FoodItemContext } from "../../contexts/FoodItemContext";

const ScanningCard = () => {
  const { foodOrder, setFoodOrder, clearState } = useContext(FoodItemContext);
  const isorderPlaced = useSelector((state: any) => state.order.isorderPlaced);
  const { theme } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const page_theme = useSelector(
    (state: any) => state.table?.business_Info?.page_layout?.instore?.cart
  );
  const text_modals = useSelector((state: any) => state?.table?.modalText);
  const orderPopData = text_modals?.filter((updated: any) =>
    "order".includes(updated?.modal_type)
  )[0];
  useEffect(() => {
    if (isorderPlaced) {
      setTimeout(() => {
        let output: any = foodOrder.map((element) => {
          return {
            ...element,
            row_items: [],
          };
        });
        setFoodOrder(output);  
        window.location.replace("/");
         localStorage.clear();
        clearState();
      }, 5000);
      dispatch(updatePlaceOrderStatus(false));
    }
  }, [isorderPlaced]);

  return (
    <div className="h-screen">
      <Header />
      <div
        className="h-4/5 flex justify-center flex-col items-center "
        style={{ background: theme.colors.secondaryColor }}
      >
        <div className="flex flex-col items-center gap-y-3">
          <>
            <div
              className="closeBtnDivide"
              data-bs-dismiss="modal"
              style={{
                backgroundColor: page_theme?.cart_modal_cross_icon_bgColor,
              }}
            ></div>
            <div className="text-center ">
              <div
                className="text-center text-uppercase thankuText "
                style={{
                  color: page_theme?.primary_cart_modal_header_text_color,
                }}
              >
                {orderPopData?.heading_text || "DANKE"}
              </div>
              <div
                className="text-center text-dark forOrderText pt-3"
                style={{
                  color: page_theme?.primary_cart_modal_second_text_color,
                }}
              >
                {" "}
                {orderPopData?.primary_text ||
                  "wir haben deine Bestellung erhalten"}
              </div>
              <div className="d-flex justify-content-center">
                <img
                  src={page_theme?.right_icon_url}
                  className="img-fluid"
                  width={120}
                  height={61}
                  alt="order"
                />
              </div>
            </div>
            <h3
              className="text-uppercase orderModalText"
              style={{
                color: page_theme?.primary_cart_modal_second_text_color,
              }}
            >
              {orderPopData?.secondary_text ||
                "DIE VAPIANISTI MACHEN SICH GLEICH AN DIE ARBEIT..."}
            </h3>
            <div className="text-center divideCalc3 mb-2"></div>
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScanningCard;

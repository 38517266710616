import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { createLocalOrder } from "../../store/actions/orderAction";
import ModalInterface from "../modal/ModalInterface";
import { useDispatch, useSelector } from "react-redux";

import { updateSessionTable } from "../../store/actions/tableActions";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export const PlaceOrder = ({
  tableNameModal,
  setTableNameModal,
  placeOrder,
}) => {
  const [selectedTable, setSelectedTable] = useState("");
  const floorPlans = useSelector((state) => state.table.floorPlans);
  const updateTableLoader = useSelector(
    (state) => state.table.updateTableLoader
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [floorPlanData, setFloorPlanData] = useState([]);
  useEffect(() => {
    if (floorPlans) {
      let floorPlanArray = [{ value: "", label: "Select" }];
      floorPlans.forEach((_item) => {
        if (![1, 2, 3, 4].includes(_item?.number)) {
          floorPlanArray.push({ value: _item?.number, label: _item?.number });
        }
      });
      setFloorPlanData([...floorPlanArray]);
    }
  }, [floorPlans]);
  return (
    <div>
      <ModalInterface
        display={tableNameModal}
        center="true"
        handleClose={() => {
          setTableNameModal(false);
        }}
        title={<div className="text-center">TABLES</div>}
        closeEvent={() => setTableNameModal(false)}
        footer
        size="lg"
        content={
          <>
            <Form>
              <Form.Group className="mb-3">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  className="basic-single"
                  classNamePrefix="select"
                  name="floorPlans"
                  options={floorPlanData}
                  onChange={(e) => {
                    setSelectedTable(e.value);
                  }}
                />
              </Form.Group>
            </Form>
          </>
        }
        footerContent={
          <>
            <Button
              disabled={updateTableLoader}
              onClick={() => {
                if (!selectedTable) {
                  alert("Please select Table");
                  return;
                }
                dispatch(
                  updateSessionTable({
                    id: placeOrder?.sessionID,
                    table_id: selectedTable,
                  })
                ).then((res) => {
                  if (!res?.success) {
                    alert(res?.response?.data?.message);
                    return;
                  }
                  setSelectedTable("");
                  setTableNameModal(false);
                  let orderPlacement = placeOrder;
                  orderPlacement.tableNumber = res?.data?.table_id;
                  dispatch(createLocalOrder(orderPlacement)).then(() => {
                    navigate("/OrderPlaced");
                  });
                });
              }}
            >
              {updateTableLoader ? (
                <span
                  className="spinner-border spinner-border-lg text-danger text-center"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <h4>Assign Table</h4>
              )}
            </Button>
          </>
        }
      />
    </div>
  );
};

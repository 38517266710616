import { useContext } from "react";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import { FoodItemContext } from "../../../../contexts/FoodItemContext";
import LoadingSpinner from "../../../LoadingSpinner";
const MainComponent = () => {
  const { isLoading } = useContext(FoodItemContext);

  return (
    <div className=" flex flex-row w-full h-4/5 ">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LeftSideBar /> <RightSideBar />
        </>
      )}
    </div>
  );
};

export default MainComponent;

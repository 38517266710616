/* eslint-disable no-unsafe-optional-chaining */
import { useContext, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { FoodItemContext } from "../../../../../../../../contexts/FoodItemContext";
import { ThemeContext } from "../../../../../../../../contexts/ThemeContext";
import { ingredientsType } from "../../../../../../../../contexts/ContextTypes";
import { useDispatch } from "react-redux";
import { AddToCart, getArtcleDiscription } from "../../../../../../../../store/actions/orderAction";
import parse from "html-react-parser";

export default function CardModal() {
  const { theme } = useContext(ThemeContext);
  const {
    selectedFoodItem,
    setSelectedFoodItem,
    setFoodOrder,
    foodOrder,
    updatedCount,
    setupdatedCount,
    orderId,
    setOrderId,
    setOrdersBlip,
    showAddFoodItemModal,
    setShowAddFoodItemModal,
  } = useContext(FoodItemContext);
  const ingredientRef = useRef<null | HTMLDivElement>(null);
  const itemImageRef = useRef<null | HTMLButtonElement>(null);
  const [description, setDiscription]:any = useState(null);
  const writeDescription = () => {
    if(typeof description === 'string'){
      return description;
    }
    if(description){
      let descEN = description?.find(
        (dsc:any) => dsc?.localeCode === "en" && dsc?.description !== ""
      );
      let descDE = description?.find(
        (dsc:any) => dsc?.localeCode === "de" && dsc?.description !== ""
      );
      if (descDE) {
        return descDE?.description;
      } else {
        return descEN?.description;
      }
    }
    return ""
  };
  selectedFoodItem.description =(writeDescription() && parse(writeDescription())) || ``
  let selectedItemOrderId = 0;
  const [selectedItemPrice, setSelectedItemPrice] = useState(0.0);
  const dispatch=useDispatch()
  const [showIngredients, setShowIngredients] = useState(false);
  const [uniqueIngredienGroups, setUniqueIngredientsGroups] = useState([]);
  let selectedIngredients: any = [];
  const handleSingleOnly = (e: any, groupId: string) => {
    let isSingleOnly = selectedFoodItem?.single_only.find(
      (id: string) => id === groupId
    );
    if (isSingleOnly) {
      let singleOnlyIngredients = selectedFoodItem?.ingredients.filter(
        (ingredient: ingredientsType) =>
          ingredient.group_id === groupId && ingredient.sku !== e.target.id
      );
      singleOnlyIngredients.forEach((singleOnlyIngredients) => {
        const checkbox = document.getElementById(
          singleOnlyIngredients.sku
        ) as HTMLInputElement;
        checkbox.checked = false;
      });
    }
  };

  // add in useEffect to remove bad state call error
  useEffect(() => {
    setSelectedFoodItem(selectedFoodItem);
    itemImageRef.current?.scrollIntoView({ behavior: "smooth" }); // scroll to bottom of modal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddFoodItemModal]);
  const mandatoryArray = selectedFoodItem?.mandatory;
  useEffect(() => {
    if (showIngredients) {
      ingredientRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showIngredients]);

  useEffect(() => {
    let uniqueIngredienGroupsArray: any = [];
    let ingredientGroups: any = [];
    let ingredients: any = [];
    // //get all groups and their ids
    for (let ingredient of selectedFoodItem?.ingredients) {
      let tempIngredient = {
        sku: "",
        group: "",
        group_id: "",
        ingredients: [],
      };
      tempIngredient.sku = ingredient.sku;
      tempIngredient.group = ingredient.group;
      tempIngredient.group_id = ingredient.group_id;
      ingredientGroups.push(tempIngredient);
      ingredients.push(ingredient);
    }
    //filter out duplicate groups and their ids
    uniqueIngredienGroupsArray = ingredientGroups.filter(
      (value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.group === value.group)
    );
    //populating products array of uniqueGroups
    for (let i = 0; i < uniqueIngredienGroupsArray.length; i++) {
      for (let j = 0; j < ingredients.length; j++) {
        if (uniqueIngredienGroupsArray[i].group === ingredients[j].group) {
          uniqueIngredienGroupsArray[i].ingredients.push(ingredients[j]);
        }
      }
    }
    setUniqueIngredientsGroups(uniqueIngredienGroupsArray);
    setSelectedItemPrice(selectedFoodItem?.price);
  }, [selectedFoodItem]);
  const getArtcleDiscriptionHandler = async (sku: string, blId: string) => {
    let res= await dispatch(getArtcleDiscription({ sku, blId }));
      setDiscription(res)
  };
   useEffect(()=>{
    if(selectedFoodItem.sku && selectedFoodItem.blId){
      getArtcleDiscriptionHandler(selectedFoodItem.sku,selectedFoodItem.blId)
    }
     
   },[selectedFoodItem.sku])
  //get all selected ingredients
  const calculateIngredientsPrice = (e: any) => {
    let updatedprice, unCheckedIngredient, checkedIngredient;
    if (!e.target.checked) {
      // if ingredient is unchecked then subtract that price
      unCheckedIngredient = selectedFoodItem?.ingredients.filter(
        (ingredient: any) => e.target.id === ingredient.sku
      );
      updatedprice = (selectedItemPrice - unCheckedIngredient[0].price).toFixed(
        2
      );
      setSelectedItemPrice(parseFloat(updatedprice));
    }
    // else if ingredients is checked then add that price to the total
    else {
      checkedIngredient = selectedFoodItem?.ingredients.filter(
        (ingredient: any) => e.target.id === ingredient.sku
      );
      updatedprice =
        parseFloat(selectedItemPrice + "") +
        parseFloat(checkedIngredient[0].price + "");
      updatedprice = parseFloat(updatedprice + "").toFixed(2);
      setSelectedItemPrice(parseFloat(updatedprice));
    }
  };
  const getSelectedIngredients = () => {
    let selectIngredientsSkus: any = [];
    const selectedCheckboxes = document.querySelectorAll(
      "input[class=selectedIngredients]"
    );
    selectedCheckboxes.forEach((checkbox: any) => {
      if (checkbox.checked) {
        selectIngredientsSkus.push(checkbox.id);
      }
    });
    selectedIngredients = selectedFoodItem?.ingredients.filter(
      (ingredient: any) => selectIngredientsSkus.includes(ingredient.sku)
    );
    selectedIngredients.map((_item:any)=>{
    let isSingleOnly = selectedFoodItem?.single_only?.includes(_item.group_id);
    return   _item["isSingleOnly"] = isSingleOnly;
    })
    return selectedIngredients;
  };
  const updateFoodOrder = () => {
    let IngredientsSelected: any = [],
      isMandatoryIngredientsAdded = true;
    IngredientsSelected = Object.values(
      selectedIngredients.map((item: any) => {
        return item.group_id;
      })
    );
    if (mandatoryArray?.length > 0) {
      let checkGroups: { [key: string]: boolean } = {};
      mandatoryArray.forEach((item) => {
        checkGroups[item] = false;
      });
      for (let i = 0; i < mandatoryArray?.length; i++) {
        for (let j = 0; j < IngredientsSelected.length; j++) {
          if (mandatoryArray[i] === IngredientsSelected[j]) {
            checkGroups[mandatoryArray[i]] = true;
          }
        }
      }
      if (!Object.values(checkGroups).includes(false)) {
        let storeId = [];
        for (let i = 0; i < foodOrder.length; i++) {
          for (let j = 0; j < foodOrder[i].row_items.length; j++) {
            storeId.push(foodOrder[i].row_items[j]._id);
          }
        }
        // check if order already exists
        const checkOrderExists = (item: any) => {
          if (item === selectedFoodItem?._id) {
            return item;
          }
        };
        let newVar = storeId.find(checkOrderExists);
        if (newVar) {
          let found = "";
          found = newVar;
          if (checkIfIngredientsExists(found)) {
            setFoodOrder(
              foodOrder.map((x) => {
                x.row_items = x.row_items.map((subItem: any) => {
                  if (subItem._id !== found)
                    return subItem; //if same ingre then coun++
                  else if (subItem.orderId === selectedItemOrderId) {
                    return {
                      ...subItem,
                      orderCount: subItem.orderCount + updatedCount,
                      updatedPrice: selectedItemPrice,
                      selectedIngredients: [...subItem.selectedIngredients],
                    };
                  }
                  return {
                    ...subItem,
                    updatedPrice: selectedItemPrice,
                    selectedIngredients: [...subItem.selectedIngredients],
                  };
                });
                return x;
              })
            );
          } else {
            createNewOrder();
          }
        } else {
          createNewOrder();
        }
        setShowAddFoodItemModal(false);
        setupdatedCount(1);
      } else {
        setShowAddFoodItemModal(true);
        isMandatoryIngredientsAdded = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select at least one mandatory item ",
        });
      }
    } else {
      let storeId = [];
      for (let i = 0; i < foodOrder.length; i++) {
        for (let j = 0; j < foodOrder[i].row_items.length; j++) {
          storeId.push(foodOrder[i].row_items[j]._id);
        }
      }
      // check if order already exists
      const checkOrderExists = (item: any) => {
        if (item === selectedFoodItem?._id) {
          return item;
        }
      };
      let newVar = storeId.find(checkOrderExists);
      if (newVar) {
        let found = "";
        found = newVar;
        if (checkIfIngredientsExists(found)) {
          setFoodOrder(
            foodOrder.map((x) => {
              x.row_items = x.row_items.map((subItem: any) => {
                if (subItem._id !== found)
                  return subItem; //if same ingre then coun++
                else if (subItem.orderId === selectedItemOrderId) {
                  return {
                    ...subItem,
                    orderCount: subItem.orderCount + updatedCount,
                    updatedPrice: selectedItemPrice,
                    selectedIngredients: [...subItem.selectedIngredients],
                  };
                }
                return {
                  ...subItem,
                  updatedPrice: selectedItemPrice,
                  selectedIngredients: [...subItem.selectedIngredients],
                };
              });
              return x;
            })
          );
        } else {
          createNewOrder();
        }
      } else {
        createNewOrder();
      }
      setupdatedCount(1);
      setShowAddFoodItemModal(false);
    }
    if (isMandatoryIngredientsAdded) setOrdersBlip(true);
  };
  const createNewOrder = () => {
    let temp: any = {};
    setOrderId(orderId + 1);
    temp.orderId = orderId;
    temp.course = selectedFoodItem.course;
    temp._id = selectedFoodItem?._id;
    temp.description = selectedFoodItem?.description;
    temp.ingredients = selectedFoodItem?.ingredients;
    temp.name = selectedFoodItem?.name;
    temp.image = selectedFoodItem?.image;
    temp.updatedPrice = selectedItemPrice;
    temp.price = selectedFoodItem?.price;
    temp.sku = selectedFoodItem?.sku;
    temp.orderCount = updatedCount;
    temp.selectedIngredients = selectedIngredients.map((item: any) => {
      return item;
    });
    
    let array = [...foodOrder];
    array.map((el: any) => {
      if (el.draggable_id === temp.course) {
        el.row_items.push(temp);
      }
    });
    console.log(array);
    let item={
      "id": selectedFoodItem._id,
      "quantity": temp.orderCount,
      "name": selectedFoodItem.name,
      "group":  selectedFoodItem.group,
      "image": selectedFoodItem.image,
      "sku": selectedFoodItem.sku,
      "course": selectedFoodItem.course,
      "price": selectedFoodItem.price,
      "prepTime": selectedFoodItem.preperation_time,
      "subItems": temp.selectedIngredients
  }
  dispatch(
    AddToCart({
      item: item,
      latestIng: temp?.ingredients

    })
  );
  };
 
  const checkIfIngredientsExists = (found: string) => {
    for (let x of foodOrder) {
      for (let subItem of x.row_items) {
        if (subItem._id === found) {
          if (
            _.differenceWith(
              selectedIngredients,
              subItem.selectedIngredients,
              _.isEqual
            ).length === 0
          ) {
            selectedItemOrderId = subItem.orderId;
            if (
              selectedIngredients.length !== subItem.selectedIngredients.length
            ) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
    }
    return false;
  };
  const handleSetOrder = () => {
    getSelectedIngredients();
    updateFoodOrder();
  };
  const [clickDisabled, setClickDisabled] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const handleAddOrders = () => {
    if (clickDisabled) {
      return;
    }
    setClickDisabled(true); // Disable clicks
    setupdatedCount(updatedCount + 1);
    setTimeout(() => {
      setClickDisabled(false); // Re-enable clicks after 2 seconds
    }, 500);
  };
  const handleDeleteOrder = () => {
    if (clickDisabled) {
      return;
    }
    if (updatedCount > 1) {
      setClickDisabled(true); // Disable clicks
      setupdatedCount(updatedCount - 1);
      setTimeout(() => {
        setClickDisabled(false); // Re-enable clicks after 2 seconds
      }, 500);
    }
  };

  const handleCloseModal = () => {
    setShowAddFoodItemModal(false);
    setShowIngredients(false);
    setupdatedCount(1);
  };
  return (
    <>
      <div
        className=" py-20 h-full px-1 sm:px-3 md:w-450 md:py-10  "
        style={{
          minWidth: "80%",
        }}
      >
        <div className=" bg-white  px-5 pt-5 rounded-t-lg h-90  lg:h-90">
          <div className="h-full">
            {/* here is our header */}
            <div
              style={{
                height: "7%",
              }}
            >
              <div className="mb-1 text-white flex justify-between">
                <button className="hover:opacity-70  ">
                  <Tooltip
                    title={
                      selectedFoodItem?.allergens.length > 0
                        ? selectedFoodItem?.allergens.map(
                            (allergen: any) => allergen.title + " "
                          )
                        : "N/A"
                    }
                    arrow
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        fill: theme.colors.tertiary2,
                      }}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 sm:h-10 sm:w-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </Tooltip>
                </button>
                <button
                  className="hover:opacity-70 "
                  onClick={handleCloseModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      fill: "red",
                    }}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 sm:h-10 sm:w-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* here is middle section */}
            <div
              className=" overflow-y-auto pb-3 w-full mt-2 md:mt-2"
              style={{ height: "90%" }}
            >
              <div className="flex flex-col gap-5">
                <div className="h-full w-full">
                  <img
                    src={selectedFoodItem?.image}
                    alt=""
                    className=" object-center w-full h-full"
                  />
                </div>
                <div className="mt-auto ">
                  <div className="flex flex-row  justify-between bg-white-300 mt-2 sm:py-3">
                    <h1 className="text-base leading-none font-extrabold sm:text-3xl ">
                      {selectedFoodItem?.name}
                    </h1>
                    <p className="text-xs  font-extrabold  sm:text-3xl ">
                      €{selectedItemPrice}
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-6 items-center">
                    <p className="text-xs w-full  line-clamp-3 md:line-clamp-3 lg:line-clamp-5 xl:line-clamp-4 text-justify sm:text-lg sm:font-medium">
                      {selectedFoodItem?.description}
                    </p>
                    <button
                      ref={itemImageRef}
                      className="rounded-md text-white text-base font-extrabold  p-2 px-3 sm:p-6 sm:px-8 sm:font-bold sm:text-lg sm:tracking-wide hover:opacity-70"
                      style={{ backgroundColor: theme.colors.primaryColor }}
                      onClick={() => {
                        setShowIngredients(!showIngredients);
                      }}
                    >
                      PERSONALIZE
                    </button>
                  </div>
                  <div
                    ref={ingredientRef}
                    className={
                      uniqueIngredienGroups.length === 0
                        ? "flex justify-center items-center h-52"
                        : ""
                    }
                  >
                    <div
                      className={
                        uniqueIngredienGroups.length === 0
                          ? "text-lg font-semibold"
                          : "hidden"
                      }
                    >
                      No Ingredients are in this Item
                    </div>
                    {uniqueIngredienGroups.map((group: any) => (
                      <div
                        key={group.sku + group.group}
                        className={
                          showIngredients
                            ? "flex flex-col ingredients"
                            : "hidden"
                        }
                      >
                        <p className="font-bold text-lg sm:text-xl mt-3 mb-3 ">
                          {group.group}
                        </p>
                        {group.ingredients.map((item: any) => (
                          <div key={item.sku + item.ingredient_name}>
                            <div className="flex flex-row justify-between ">
                              <p className="sm:text-lg">
                                {item.ingredient_name}
                              </p>
                              <div className="flex flex-row gap-6">
                                <p className="text-base sm:text-lg">
                                  {item.price}€
                                </p>
                                {/* default set standard ingrdients checked */}
                                <input
                                  style={{ width: "16px" }}
                                  type="checkbox"
                                  value={item.group_id}
                                  id={item.sku}
                                  defaultChecked={
                                    item?.type === "Standard" ? true : false
                                  }
                                  className="selectedIngredients"
                                  onChange={(e) => {
                                    handleSingleOnly(e, item.group_id);
                                    calculateIngredientsPrice(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="border-b-2 border-black"></div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* here is our footer */}
        <div
          className="rounded-b-lg p-3 "
          style={{
            backgroundColor: theme.colors.primaryColor,
            minHeight: "80px",
          }}
        >
          <div className="px-4 flex flex-row justify-between pb-2 ">
            <div className="flex flex-row justify-between px-5 gap-x-2 ">
              <div
                  className={`bg-white px-2 h-fit mt-3 cursor-pointer sm:px-4 sm:py-1 ${isDisabled ? "disabled" : ""}`}
                  onClick={() => {
                    if (!isDisabled) {
                      handleDeleteOrder(); // Call your function here
                      setDisabled(true); // Disable the button
                      setTimeout(() => {
                        setDisabled(false); // Re-enable the button after 2 seconds
                      }, 500);
                    }
                  }}
              >
                <button className="sm:text-lg sm:font-extrabold plus-minus-button" disabled={isDisabled}>-</button>
              </div>
              <div className="text-white h-fit font-semibold text-3xl mt-1 cursor-pointer sm:py-1">
                <button className="sm:text-4xl">{updatedCount}</button>
              </div>
              <div
                  className={`bg-white px-2 sm:px-4 sm:py-1 h-fit mt-3 cursor-pointer ${isDisabled ? "disabled" : ""}`}
                  onClick={() => {
                    if (!isDisabled) {
                      handleAddOrders(); // Call your function here
                      setDisabled(true); // Disable the button
                      setTimeout(() => {
                        setDisabled(false); // Re-enable the button after 2 seconds
                      }, 500);
                    }
                  }}
              >
                <button className="sm:text-lg sm:font-extrabold plus-minus-button" disabled={isDisabled}>+</button>
              </div>
            </div>
            <div
              className=" rounded-md text-white  mt-2 px-7 sm:px-14 sm:py-2 justify-center flex w-fit hover:opacity-70 cursor-pointer"
              style={{ backgroundColor: theme.colors.tertiary }}
              onClick={handleSetOrder}
            >
              <button className=" text-base font-extrabold sm:text-2xl ">
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { SET_SESSION } from "../types";
import { orderInfo, setTable, tableID } from "./tableActions";
const UserRepository = RepositoryFactory.get("user");
export const joinSession = (payload) => async (dispatch) => {
  try {
    let table = await UserRepository.checkUser(payload);
    console.log(table);
    if (table) {
      dispatch(tableID({ id: table.data[0]._id }));
      localStorage.setItem("table", JSON.stringify(table));
      dispatch(setTable(table?.data[0]?.table_name));
      dispatch(
        orderInfo({
          businessLocationId: payload?.buid,
          tableNumber: payload?.table_no,
          idc: (payload?.idc)?.toLowerCase(),
        })
      );
      dispatch({
        type: SET_SESSION,
        payload: table?.data[0],
      });
      sessionStorage.setItem("session_type", "join");
      return table;
    }
  } catch (err) {
    console.log("error " + err);
    return err;
  }
};

import {
  FoodCategoryType,
  FoodItemsType,
  ThemeType,
  FoodOrdersType,
} from "./ContextTypes";
// import image1 from '../assets/company-name-logo.png';
export const defaultTheme: ThemeType = {
  colors: {
    primaryColor: "#86744E",
    secondaryColor: "#D6BD99",
    tertiary: "#C49F6E",
    tertiary2: "#966518",
    tertiary3: "#936418",
  },
  images: {
    companyLogo:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150774/company_name_logo_ghualf.png",
    mainLogo:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/main_logo_gzunlj.png",
    companyMainLogo:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663663878/Capture-removebg-preview_bjjxsw.png",
    videImage:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663664374/Capturenghjjgdf_kogim6.png",
    food_pic:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
    food_pic2:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663574900/pexels-ella-olsson-1640777_ny1vge.jpg",
    beep: "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150774/beep_rpw0ay.png",
  },
};
export const defaultFoodItems: FoodItemsType[] = [
  // {
  //   foodCategoryName: "Italian",
  //   foodItems:[
  //    {
  //     _id: "1",
  //     name:'Karala',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "KERA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SIRKA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "BUTTER",
  //         price: 45.5 },
  //       {
  //         ingredientName: "MATTER",
  //         price: 45.5 },
  //       {
  //         ingredientName: "OIL",
  //         price: 45.5 },
  //       {
  //         ingredientName: "PATI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "CHENI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SUGAR",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SALT",
  //         price: 45.5 },
  //       {
  //         ingredientName: "KINOR",
  //         price: 45.5 },
  //     ]
  //   },
  //    {
  //     _id: "2",
  //     name:'Khera',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663574900/pexels-ella-olsson-1640777_ny1vge.jpg',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "KERA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SIRKA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "BUTTER",
  //         price: 45.5 },
  //       {
  //         ingredientName: "MATTER",
  //         price: 45.5 },
  //       {
  //         ingredientName: "OIL",
  //         price: 45.5 },
  //       {
  //         ingredientName: "PATI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "CHENI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SUGAR",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SALT",
  //         price: 45.5 },
  //       {
  //         ingredientName: "KINOR",
  //         price: 45.5 },
  //     ]
  //   },
  //    {
  //     _id: "3",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "4",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "5",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "6",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "7",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "8",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //    {
  //     _id: "9",
  //     name:'Khado',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 }
  //     ]
  //   },
  //   ]
  // } ,
  // {
  //   foodCategoryName: "Thai",
  //   foodItems:[
  //    {
  //     _id: "10",
  //     name:'Karala',
  //     image:'https://res.cloudinary.com/dudc7jc8m/image/upload/v1663574900/pexels-ella-olsson-1640777_ny1vge.jpg',
  //     description: 'Knusprige Teigtaschen mit Hummus oder Taratura',
  //     price: 4.5,
  //     ingredients: [
  //       {
  //         ingredientName: "MOZZARELLA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "SALAM",
  //         price: 45.5 },
  //       {
  //         ingredientName: "OLIVES",
  //         price: 45.5 },
  //       {
  //         ingredientName: "PEPPERION",
  //         price: 45.5 },
  //       {
  //         ingredientName: "HAM",
  //         price: 45.5 },
  //       {
  //         ingredientName: "MIRCHI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "GARAM MASALA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "ZERA",
  //         price: 45.5 },
  //       {
  //         ingredientName: "HALDI",
  //         price: 45.5 },
  //       {
  //         ingredientName: "KALI MIRCH",
  //         price: 45.5 },
  //     ]
  //   }
  //   ]
  // }
];

export const defaultFoodCategory: FoodCategoryType[] = [
  {
    categoryName: "Italian",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Thai",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Mexican",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Indian",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Bhendi",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Karala",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Khare",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Tomato",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Potato",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Tori",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
  {
    categoryName: "Dal Chana",
    image:
      "https://res.cloudinary.com/dudc7jc8m/image/upload/v1663150776/food_pic_1_mnmdxu.png",
  },
];
// export const defaultFoodOrders: FoodOrdersType[] = [
//   {
//     draggable_id: "ENTRIES",
//     row_items: [],
//   },
//   {
//     draggable_id: "MAIN",
//     row_items: [],
//   },
//   {
//     draggable_id: "SIDES",
//     row_items: [],
//   },
// ];
export const defaultFoodOrders: FoodOrdersType[] = [
  {
    draggable_id: "starters",
    name:"Vorspeise",
    row_items: [],
  },
  {
    draggable_id: "main course",
    row_items: [],
    name:"Hauptgericht",

  },
  {
    draggable_id: "dessert",
    row_items: [],
    name:"Nachtisch",

  },
  {
    draggable_id: "to drink",
    row_items: [],
    name:"Getränke",

  },
];

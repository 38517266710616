import { useContext } from "react";
import "./loadingspinner.css";
import { ThemeContext } from "../../contexts/ThemeContext";

export default function LoadingSpinner() {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="w-full  h-full flex items-center justify-center"
      style={{ backgroundColor: theme.colors.secondaryColor }}
    >
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

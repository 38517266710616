import React, { useCallback, useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { FoodItemContext } from "../../contexts/FoodItemContext";
import { CreatedSessionContext } from "../../contexts/SessionContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { FoodOrdersType, row_items } from "../../contexts/ContextTypes";
import { PlaceOrder } from "./placeOrder";
// import { createLocalOrder } from "../../services/order/createorder";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { createLocalOrder } from "../../store/actions/orderAction";
import { v4 as uuidv4 } from "uuid";
// import ModalInterface from "../modal/ModalInterface";
import "./style.css";
function DragNDrop() {
  const tableInfo = useSelector((state: any) => state.table);
  const orderInfo = useSelector((state:any) => state.table.orderInfo);
  const order = useSelector((state: any) => state.order.ordersPlaced);
  const { theme } = useContext(ThemeContext);
  const { foodOrder, setFoodOrder, setSelectedOutlet } =
    useContext(FoodItemContext);

  const { setCreatedSession } = useContext(CreatedSessionContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableNameModal, setTableNameModal] = useState(false);
  const [orderPlaceddata, setOrderPlaceddata] = useState({});
  const orderPlacedLoader =useSelector((state: any) => state.order.orderPlacedLoader)
  // const [showFinal, setShowFinal] = useState(false);
  const isorderPlaced = useSelector((state: any) => state.order.isorderPlaced);
  // const page_theme = useSelector((state:any) => state.table?.business_Info?.page_layout?.instore?.cart);
  // let count = 0;
  const handleTotalPrice = useCallback(() => {
    let fullPrice: any = 0;
    foodOrder.forEach((order: FoodOrdersType) => {
      order.row_items.forEach((item: row_items) => {
        let temp: any = 0;
        let selectedIngredientSum: any = 0; // store total price of ingredients
        item.selectedIngredients.forEach((selectedIngredient: any) => {
          //handling price of ingredients
          selectedIngredientSum =
            parseFloat(selectedIngredientSum) +
            parseFloat(selectedIngredient.price);
        });
        temp = parseFloat(temp) + parseFloat(selectedIngredientSum); //add total price of ingredients to the price
        temp = parseFloat(temp) + parseFloat(item?.price?.toString()); //add price of item in total price
        temp = (
          parseFloat(temp) * parseFloat(item?.orderCount.toString())
        ).toFixed(2); // multiply total price with order count
        fullPrice = parseFloat(temp) + parseFloat(fullPrice);
      });
    });
    setTotalPrice(fullPrice.toFixed(2));
  }, [foodOrder]);
  const handleTotalSum = useCallback(() => {
    handleTotalPrice();
  }, [handleTotalPrice]);

  useEffect(() => {
    handleTotalSum();
  }, [foodOrder, handleTotalSum]);
  useEffect(() => {
    if (isorderPlaced) {
      // setShowFinal(true);
      setTimeout(() => {
        navigate("/OrderPlaced");
      }, 1000);
    }
  }, [isorderPlaced]);

  const handleMainMenuClick = () => {
    navigate("/dashboard");
    setSelectedOutlet("Dashboard");
  };
  const handlePlaceOrder = async () => {
    if(order.payment_status ==="paid"){
      alert("payment In process You can not order")
      return;
    }
    let session = {
      bump: tableInfo?.session?.bump,
      businessLocationId: tableInfo?.session?.businessLocationId,
      createdAt: tableInfo?.session?.createdAt,
      space_used: tableInfo?.session?.space_used,
      station: tableInfo?.session?.station,
      status: tableInfo?.session?.status,
      table_id: tableInfo?.session?.table_id,
      table_name: tableInfo?.session?.table_name,
      __v: 0,
      _id: tableInfo?.session?._id,
    };
    setCreatedSession(session);
    let orderItems: any[] = [];
    foodOrder.forEach((item) => {
      item.row_items?.forEach((item) => {
        orderItems.push(item);
      });
    });
    console.log(orderItems);
    if (orderItems.length > 0) {
      let updatedItems: any = [];
      for (let i = 0; i < orderItems.length; i++) {
        updatedItems[i] = {
          name: orderItems[i].name,
          quantity: orderItems[i].orderCount,
          sku: orderItems[i].sku,
          course: orderItems[i].course,
          price: orderItems[i].price,
          subItems: orderItems[i].selectedIngredients,
        };
      }
      const placeOrder = {
        businessLocationId: "771247277342722",
        order_type: "served",
        sessionID: tableInfo?.session?._id,
        thirdPartyReference: uuidv4(),
        endpointId: "Live Hook",
        customerInfo: {
          firstName: "Test",
          lastName: "Doe",
          thirdPartyReference: "ZEN-7074522d-c71d-403f",
          email: "test@gmail.com",
          contactNumberAsE164: "012345678",
          notes: "nothing",
          salutation: "null",
        },
        orderNote: "Test Order",
        maxTimeToAttemptOrderDeliverToPos: 60000,
        tableNumber: tableInfo?.session?.table_id,
        cardId:orderInfo.idc,
        clientCount: false,
        items: updatedItems,
        min_TTL: 60000,
      };
      if (
        order?.length === 0 ||
        order[0]?.salesEntries?.length === 0 ||
        placeOrder?.tableNumber === "1"
      ) {
        setOrderPlaceddata(placeOrder);
        setTableNameModal(true);
      } else {
        dispatch(createLocalOrder(placeOrder));
      }
    } else {
      // swal alert when no order exists in the cart
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There are no items in your cart.",
      });
    }
  };
  const getList = (droppableId: any) => {
    let item_list = foodOrder;
    let filtered_item = item_list.find(
      (tappedItem) => tappedItem.draggable_id === droppableId
    );
    return filtered_item;
  };

  const getIndex = (droppableId: any) => {
    let item_list = foodOrder;
    let findIndex = item_list.findIndex(
      (tappedItem) => tappedItem.draggable_id === droppableId
    );
    return findIndex;
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    let result = Array.from(list.row_items);
    let result_old = result[startIndex];
    let result_new = result[endIndex];
    result[startIndex] = result_new;
    result[endIndex] = result_old;
    let new_obj = { ...list, row_items: result };
    return new_obj;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    try {
      console.log("move");
      const selectedObj = foodOrder.find(
        (item: any) => item.draggable_id === droppableSource.droppableId
      )?.row_items[droppableSource.index];
      let output: any = foodOrder.map((element) => {
        return {
          ...element,
          row_items:
            element.draggable_id === droppableSource.droppableId
              ? element.row_items.filter(
                  (subElement, index) => index !== droppableSource.index
                )
              : element.draggable_id === droppableDestination.droppableId
              ? [...element.row_items, selectedObj]
              : element.row_items,
        };
      });

      setFoodOrder(output);

      return output;
    } catch (e) {
      console.log("move ::  exception", e);
    }
  };

  const onDragEnd = (result: any) => {
    try {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      let state_item_list = foodOrder;

      if (source.droppableId === destination.droppableId) {
        let result_index = getIndex(source.droppableId);
        let result_items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );
        state_item_list[result_index] = result_items;

        setFoodOrder([...state_item_list]);
      } else {
        let result_m = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );
        if (
          result_m === undefined ||
          result_m.source === undefined ||
          result_m.dest === undefined
        ) {
          return;
        }
      }
    } catch (e) {
      console.log("onDragEnd ::  exception", e);
    }
  };

  const [clickDisabled, setClickDisabled] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const handleDeleteOrders = (itemId: any) => {
    if (clickDisabled) {
      return;
    }
    setClickDisabled(true); // Disable clicks
    console.log("removing order");
    setFoodOrder(
        foodOrder.map((x) => {
          x.row_items = x.row_items.map((subItem: any) => {
            if (subItem.orderCount <= 1) return subItem;
            if (subItem.orderId !== itemId) return subItem;
            return {
              ...subItem,
              orderCount: subItem.orderCount - 1,
            };
          });
          return x;
        })
    );
    setTimeout(() => {
      setClickDisabled(false); // Re-enable clicks after 2 seconds
    }, 500);
  };

  const handleAddOrders = (itemId: any) => {
    if (clickDisabled) {
      return;
    }
    setClickDisabled(true); // Disable clicks
    console.log("addorders");
    setFoodOrder(
        foodOrder.map((x) => {
          x.row_items = x.row_items.map((subItem: any) => {
            if (subItem.orderCount < 1) return subItem;
            if (subItem.orderId !== itemId) return subItem;
            return {
              ...subItem,
              orderCount: subItem.orderCount + 1,
            };
          });
          return x;
        })
    );
    setTimeout(() => {
      setClickDisabled(false); // Re-enable clicks after 2 seconds
    }, 500);
  };

  const handleDeletefoodOrder = (orderId: number) => {
    setFoodOrder(
      foodOrder.map((x) => {
        x.row_items = x.row_items.filter(
          (subItem: any) => subItem.orderId !== orderId
        );
        return x;
      })
    );
  };

  return (
    <div className=" h-full overflow-y-auto px-2 sm:px-5">
      {/* here  is header  */}
      <div className="relative mt-16" style={{ height: "10%" }}>
        <div className="absolute left-0 flex flex-col">
          <div className=" flex flex-row text-2xl font-bold">
            <p>ORDER</p>
            <p className="text-white">*</p>
          </div>
          <p>PLACE DISH INTO COURSES</p>
        </div>
        <div className="absolute right-0">
          <li className="font-sans block mt-4 lg:inline-block lg:mt-0 lg:ml-6 align-middle text-black hover:text-gray-700">
            <svg className="flex-1 w-10 h-10 fill-current" viewBox="0 0 24 24">
              <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z" />
            </svg>
            <span
              className="absolute right-0 top-0 rounded-full w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center"
              style={{ backgroundColor: theme.colors.primaryColor }}
            >
              {foodOrder[0].row_items.length +
                foodOrder[1].row_items.length +
                foodOrder[2].row_items.length}
            </span>
          </li>
        </div>
      </div>
      {/* here middle section  */}
      <div className="">
        <DragDropContext onDragEnd={onDragEnd}>
          {foodOrder?.map((item) => (
            <Droppable
              droppableId={item?.draggable_id}
              direction="vertical"
              key={item?.draggable_id}
            >
              {(provided) => (
                <div
                  className="h-full sm:flex sm: flex-col sm:p-2 sm:gap-y-2 md:p-6 md:pl-0"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <span className="font-bold">{item.name.toUpperCase()}</span>
                  <div
                    className=" w-full grid grid-cols-1 xsm:grid-cols-2  gap-2 sm:flex sm:p-0 sm:flex-col sm:gap-2"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {/* <div
                      className={item?.row_items?.length === 0 ? "" : "hidden"}
                    >
                      No items in{" "}
                      {item?.draggable_id?.toUpperCase() +
                        item?.draggable_id?.slice(1)?.toLowerCase()}
                    </div> */}
                    {item?.row_items?.map((item_row: any, index) => {
                      return (
                        <Draggable
                          key={index + ""}
                          draggableId={index + ""}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="bg-white w-full sm:w-full sm:h-28 sm:p-0 py-3 px-3 rounded-lg sm:rounded-none "
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                                flexDirection: "row",
                              }}
                            >
                              <div className="w-full flex flex-col sm:flex sm:flex-row justify-center">
                                <div className="w-full sm:w-1/4 lg:w-1/5 ">
                                  <img
                                    className="w-full h-28 sm:rounded-none object-cover"
                                    src={item_row.image}
                                    alt=""
                                  />
                                </div>
                                <div className="flex flex-col justify-center items-center sm:flex-row w-full sm:pl-6 sm:py-2 sm:pr-2 h-full sm:items-start">
                                  <div className="flex flex-col  sm:flex-row w-full sm:w-1/2 gap-x-3 sm:justify-center h-fit ">
                                    <div className="flex flex-col sm:gap-y-2  sm:items-start sm:justify-start w-full sm:w-full">
                                      <div className="text-base flex justify-center items-center text-center sm:text-lg font-bold sm:block sm:justify-start ">
                                        {item_row.name}
                                      </div>
                                      <div className="flex flex-row gap-x-2 sm:gap-x-2 sm:block w-full justify-center text-sm sm:text-base">
                                        <div
                                          className={
                                            item_row.selectedIngredients
                                              .length > 0
                                              ? "flex flex-row justify-center sm:justify-start w-full gap-x-12 flex-wrap "
                                              : "h-12 flex items-start justify-start w-full"
                                          }
                                        >
                                          <div
                                            className={
                                              item_row.selectedIngredients
                                                .length === 0
                                                ? "text-center"
                                                : "hidden"
                                            }
                                          >
                                            <p>No selected ingredients</p>
                                          </div>
                                          {item_row.selectedIngredients.map(
                                            (ingredient: any, index: number) =>
                                              index < 3 ? (
                                                <div
                                                  key={
                                                    ingredient.sku +
                                                    ingredient.ingredient_name
                                                  }
                                                  className="flex flex-row gap-1 sm:gap-2 w-fit text-xs"
                                                >
                                                  <button className="w-32 xsm:w-fit sm:w-fit items-center sm:text-start ">
                                                    {ingredient.ingredient_name}
                                                  </button>
                                                  <p className="text-center sm:text-start">
                                                    {ingredient?.price} €
                                                  </p>
                                                </div>
                                              ) : null
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-1/4  px-2 justify-between pb-5 items-center leading-none h-full gap-y-5">
                                    <div className="text-lg font-bold flex items-center justify-center sm:items-start w-20 sm:w-fit ">
                                      {item_row.updatedPrice} €
                                    </div>
                                    <div className={`flex flex-row gap-x-3 leading-none mt-1 items-end ${isDisabled ? "disabled" : ""}`}>
                                      <button
                                          className="bg-slate-200 px-1 cursor-pointer"
                                          onClick={() => {
                                            if (!isDisabled) {
                                              handleDeleteOrders(item_row.orderId);
                                              setDisabled(true); // Disable the button
                                              setTimeout(() => {
                                                setDisabled(false); // Re-enable the button after 2 seconds
                                              }, 500);
                                            }
                                          }}
                                      >
                                        -
                                      </button>
                                      <p className="bg-slate-200 px-1">
                                        {item_row.orderCount}
                                      </p>
                                      <button
                                          className="bg-slate-200 px-1 cursor-pointer"
                                          onClick={() => {
                                            if (!isDisabled) {
                                              handleAddOrders(item_row.orderId);
                                              setDisabled(true); // Disable the button
                                              setTimeout(() => {
                                                setDisabled(false); // Re-enable the button after 2 seconds
                                              }, 500);
                                            }
                                          }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flex sm:flex-row-reverse flex-row sm:w-1/4 w-fit sm:items-start gap-x-2 sm:m-0 items-end mr-0 ml-auto">
                                    <button>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                        />
                                      </svg>
                                    </button>
                                    <button
                                      className="bg-slate-200 p-1"
                                      onClick={() => {
                                        handleDeletefoodOrder(item_row.orderId);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>

      {/* here is footer  */}
      <div className="flex flex-col gap-3 py-2">
        <div className="flex flex-col items-end">
          <div className="flex flex-col items-end">
            <div className="flex">
              <div className="flex gap-x-2 mt-1">
                <p className="mt-1 font-medium">Sub total </p>
                <p className="text-lg font-bold">{totalPrice} €</p>
              </div>
            </div>
            <div className=" border-b-4 border-black w-36 mt-1"></div>
          </div>
          <div
            className="text-lg font-bold px-2 py-1 rounded-3xl mt-1 hover:opacity-70"
            style={{ backgroundColor: theme.colors.tertiary }}
          >
            {orderPlacedLoader ? (
                <span
                  className="spinner-border spinner-border-lg text-danger text-center"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <button className="flex flex-row" onClick={handlePlaceOrder}>
              <p className="text-white">*</p>
              <p>Place order</p>
            </button>
              )}      
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="flex flex-row justify-center items-center gap-x-3 text-lg font-extrabold hover:opacity-70"
            onClick={handleMainMenuClick}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="4"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <p>WOULD YOU LIKE TO</p>{" "}
              <div className="flex flex-row">
                <p>ADD SOMETHING?</p>
                <span>*</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      {tableNameModal ? (
        <PlaceOrder
          setTableNameModal={setTableNameModal}
          tableNameModal={tableNameModal}
          placeOrder={orderPlaceddata}
        />
      ) : null}
    </div>
  );
}

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  ...draggableStyle,
});

export default DragNDrop;

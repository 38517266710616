import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FoodItemContext } from "../../../../../contexts/FoodItemContext";
import { ThemeContext } from "../../../../../contexts/ThemeContext";
import "./style.css";

const LefSideBar = () => {
  const { theme } = useContext(ThemeContext);
  const {
    setSelectedCategory,
    selectedCategory,
    setSelectedOutlet,
    groups,
    setSelectedGroup,
    selectedGroup,
    setShowAddFoodItemModal,
    // setIsLoading,
  } = useContext(FoodItemContext);
  const ref: any = useRef(null);
  const scroll = (scrollOffset: any) => {
    ref.current.scrollTop += scrollOffset;
  };

  const navigate = useNavigate();

  const handleCategorySelection = (category: any) => {
    navigate("/dashboard");
    setSelectedOutlet("Dashboard");
    setSelectedCategory(category);
    setSelectedGroup(category);
  };
  return (
    // <div className=" w-28 md:w-1/5   bg-white py-2 flex flex-col justify-between ">
    <div className=" w-1/3 xsm:w-1/4 md:w-1/5 bg-white  py-0.5 flex flex-col justify-between ">
      <div
        className="flex justify-center cursor-pointer  hover:bg-tintbrown hover:text-gray-500 transform hover:scale-110 transition duration-300"
        onClick={() => scroll(-50)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      </div>
      <div
        style={{ height: "92%" }}
        ref={ref}
        className="overflow-scroll  pt-2 scroll-smooth flex flex-col mx-4 md:gap-y-5 gap-x-1 md:mx-5 lg:mx-11 no-scrollbar"
      >
        {groups.map((group: any) => {
          return (
            <div
              key={group?.groupId}
              onClick={() => {
                handleCategorySelection(group);
                setShowAddFoodItemModal(false);
              }}
              className={
                selectedGroup === group?.groupId
                  ? "flex flex-col justify-center items-center"
                  : "flex flex-col justify-center items-center "
              }
            >
              <button>
                <img
                  style={{
                    border:
                      selectedCategory === group
                        ? `6px solid ${theme.colors.secondaryColor}`
                        : "",
                  }}
                  src={group?.groupImage}
                  alt=""
                  className=" object-contain rounded-lg w-28 h-20 sm:w-40 md:h-fit 2xl:w-72 2xl:h-62"
                />
              </button>
              <div className="flex flex-row text-xl text-center">
                <p className="font-black text-sm sm:text-xl  md:text-2xl xl:text-3xl 2xl:text-4xl">
                  {group?.groupName}
                </p>
                <p style={{ color: theme.colors.secondaryColor }}>*</p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="flex justify-center cursor-pointer hover:bg-tintbrown transform hover:text-gray-500 hover:scale-110 transition duration-300"
        onClick={() => scroll(50)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
    </div>
  );
};

export default LefSideBar;

import React, { useCallback, useEffect, useRef, useState } from "react";
import Scanner from "../Scanner/Scanner";
import "./style.css";
import ModalInterface from "../../modal/ModalInterface";
const Scan = (props) => {
  const inputRef = useRef(null);
  const abortController = new AbortController();
  const [cardsuccessScan, setCardSuccessScan] = useState(false);

  abortController.signal.onabort = () => {
    // All NFC operations have been aborted.
  };
  // const [message, setMessage] = useState("");
  // const [serialNumber, setSerialNumber] = useState("");
  const { actions, setActions } = props.actionsValue;
  const scan = useCallback(async () => {
    inputRef.current.focus();
  }, [setActions]);

  function convertNfcUid(nfcUid) {
    // Remove any colons or spaces in the UID
    nfcUid = nfcUid.replace(/:|\s/g, "");
    // // Pad the UID with zeros if it is less than 6 characters long
    // nfcUid = nfcUid.padStart(6, "0");
    return nfcUid.slice(nfcUid.length - 14);
  }

  useEffect(() => {
    scan();
  }, [scan]);
  const cardIdHandler = (e) => {
    setCardSuccessScan(true);
    setTimeout(()=>{
    inputRef.current.blur();
    const convertedUid = convertNfcUid(e.target.value);
    props.setTableName(convertedUid.toLowerCase());
    setActions({
      scan: "scanned",
      write: null,
    });
    },1000)
    // alert(e.target.value);
    // inputRef.current.blur();
    // const convertedUid = convertNfcUid(e.target.value);
    // props.setTableName(convertedUid);
    // setActions({
    //   scan: "scanned",
    //   write: null,
    // });
  };
  return (
    <>
      <ModalInterface
        display={actions.scan === "scanning"}
        center="true"
        onClick={() => {
          setActions({
            scan: "scanned",
            write: null,
          });
        }}
        handleClose={() => {
          setCardSuccessScan(false);
          setActions({
            scan: "scanned",
            write: null,
          });
        }}
        closeEvent={() => {
          setCardSuccessScan(false);
          setActions({
            scan: "scanned",
            write: null,
          });
        }}
        size="sm"
        content={
          <>
            <Scanner
              status={actions.scan}
              actionsValue={props.actionsValue}
              cardsuccessScan={cardsuccessScan}
            ></Scanner>
            <input
              style={{}}
              onChange={cardIdHandler}
              type="text"
              className="nfc_uid_field"
              ref={inputRef}
            />
          </>
        }
      />

      {/* {actions.scan === "scanning" ? (
        <Scanner
          status={actions.scan}
          actionsValue={props.actionsValue}
        ></Scanner>
      ) : (
        <div>
          
        </div>
      )} */}
    </>
  );
};

export default Scan;

import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FoodItemContext } from "../../contexts/FoodItemContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import NFCScane from "../Nfc/NFCScane";
import { useDispatch } from "react-redux";
import { joinSession } from "../../store/actions/userAction";
import {
  getBusinessFlorrPlans,
  getBusinessInfo,
  getTextAndHeading,
} from "../../store/actions/tableActions";
import {
  getCourses,
  loadOrdersBySession,
} from "../../store/actions/orderAction";

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { foodOrder, setFoodOrder, setSelectedOutlet } =
    useContext(FoodItemContext);
  let [queryValue] = useSearchParams();
  let table_no = queryValue.get("table_no");
  let buid = queryValue.get("buid");
  let idc = queryValue.get("idc");
  let searchParams = window.location.search;
  let validateQuery =
    searchParams.includes("buid") && searchParams.includes("table_no");

  useEffect(() => {
    if (
      searchParams.length >= 0 &&
      validateQuery &&
      table_no !== "" &&
      String(table_no) !== "null" &&
      String(table_no) !== "undefined" &&
      buid !== "" &&
      String(buid) !== "null" &&
      String(buid) !== "undefined" &&
      buid !== "" &&
      idc
    ) {
      dispatch(getTextAndHeading(buid));
      dispatch(getCourses());
      dispatch(getBusinessFlorrPlans(buid));
      dispatch(getBusinessInfo({ table_no, buid })).then((res) => {
        if (res.success) {
          joinSessionHandler();
        }
      });
      // joinSessionHandler()
    } else {
      dispatch({
        type: "LOG_OUT",
      });
      localStorage.clear();
      let output = foodOrder.map((element) => {
        return {
          ...element,
          row_items: [],
        };
      });
      setFoodOrder(output);
    }
  }, [searchParams]);
  const joinSessionHandler = () => {
    if (!idc && !table_no && !buid) {
      return;
    }
    dispatch(joinSession({ idc, table_no, buid })).then((resp) => {
      if (resp?.success) {
        navigate("/dashboard");
        setSelectedOutlet("Dashboard");
        dispatch(
          loadOrdersBySession({
            businessLocationId: buid,
            sessionId: resp?.data[0]?._id,
          })
        );
      } else {
        alert("DIESE RUNDENNAME EXISTIERT NICHT");
      }
    });
  };

  // const handleScanCard = () => {
  //   navigate("/dashboard");
  //   setSelectedOutlet("Dashboard");
  // };
  return (
    <div
      className=" w-screen h-screen  flex flex-col items-center overflow-y-auto "
      style={{ background: theme.colors.secondaryColor }}
    >
      <div
        className="h-full w-full flex flex-col justify-center"
        style={{ background: theme.colors.secondaryColor }}
      >
        <div className="h-fit flex flex-col justify-center items-center ">
          <div className=" mt-auto flex flex-col justify-center items-center">
            <img
              src={theme.images.mainLogo}
              alt="Tailwind Play"
              className="h-44 w-44 sm:h-52 sm:w-52 md:h-56 md:w-56 lg:h-64 lg:w-64 xl:h-72 xl:w-72  2xl:h-80 2xl:w-80 mt-12"
            />
            <div className="flex flex-col items-center pt-5">
              <p
                className=" text-3xl md:text-4xl lg:text-5xl font-serif text-center"
                style={{ color: theme.colors.primaryColor }}
              >
                Welcome to
              </p>
              <img
                src={theme.images.companyMainLogo}
                alt=""
                className=" h-16 w-48 sm:h-24 sm:w-64 md:h-28 md:w-72 lg:h-32 lg:w-80 xl:h-36 xl:w-96"
              />
            </div>
          </div>
        </div>
        <div className=" w-full h-fit flex flex-col">
          <div className="mt-auto">
            {/* <div className="w-full flex items-center justify-center cursor-pointer sm:h-1000 p-4">
              <iframe
                src="https://player.vimeo.com/video/152289766?h=cda7ebbfe4"
                title="video"
                allowFullScreen
                // className="  md:w-900 md:h-900 lg:w-1050 lg:h-full"
                className="w-full h-full "
              ></iframe>
            </div> */}
            <NFCScane />
            {/* <button
              className="flex justify-center items-center w-full cursor-pointer hover:opacity-70 h-32"
              // onClick={handleScanCard}
            >
              <span className="text-white text-2xl md:text-3xl lg:text-4xl">
                *
              </span>
              <span className="font-bold text-[#84744E] text-2xl md:text-3xl lg:text-4xl ">
              Hier klicken, um zu bestellen
              </span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Welcome;
